import React from 'react';
import {Link} from "react-router-dom";

import './ProductCard.scss';

import ProductImg from "./img/product.jpg";

const ProductCardComponent = () => {
    return (
        <Link to="/product" className="product-card">
            <div className="img">
                <img src={ProductImg} alt=""/>
            </div>
            <div className="data">
                <h5>Panou perete IsoPer N</h5>
                <p>Acest tip de panoi poate fi montat atat orizontal cat si vertical</p>
            </div>
        </Link>
    );
};

export default ProductCardComponent;