import React from 'react';

import Filters from "../../../common/filters/main/FiltersComponent";
import Button from "../../../common/button/main/ButtonComponent";
import ProductCard from "../../../common/product-card/main/ProductCardComponent";
import CtaQuote from "../../../common/cta-quote/main/CtaQuoteComponent"
import Features from "../subcomponents/features/main/FeaturesComponent";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Categories.scss'

import Banner from './img/banner-categories.jpg';
import Envelope from './img/icon-envelope.png';

const CategoriesComponent = () => {
    return (
        <div className="categories-content">
            <Button
                href="#"
                classList="ask-offer"
            >
                <img src={Envelope} alt=""/>
                SOLICITĂ OFERTĂ
            </Button>

            <Container fluid>
                <Row>
                    <Col md={3}>
                        <Filters />
                    </Col>

                    <Col md={9}>
                        <div className="banner">
                            <img src={Banner} alt=""/>
                            <Button
                                href="#"
                                classList="white-btn"
                            >
                                Calculator preț Hale
                            </Button>
                        </div>
                        <div className="listing">
                            <Row>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                                <Col lg={4} sm={6}>
                                    <ProductCard />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <CtaQuote />
            </Container>

            <Features />
        </div>
    );
};

export default CategoriesComponent;