import React, {Component} from 'react';

import Categories from "./CategoriesComponent";


class CategoriesContainer extends Component {
    render() {
        return (
            <>
                <Categories />
            </>
        );
    }
}

export default CategoriesContainer;