import React from 'react';
import {Link} from "react-router-dom";

import './Button.scss';

const ButtonComponent = (props) => {
    return (
        <Link
            to={props.href}
            className={`${props.classList} main-btn`}
        >
            {props.children}
        </Link>
    );
};

export default ButtonComponent;