import React, {Component} from 'react';

import Product from './ProductComponent';

class ProductContainer extends Component {
    state = {
        hiddenText: true
    };

    showText = () => {
        let hiddenTextVal = !this.state.hiddenText;

        this.setState({
            ...this.state,
            hiddenText: hiddenTextVal
        })
    };

    render() {
        return (
            <>
                <Product
                    hiddenText={this.state.hiddenText}
                    showText={this.showText}
                />
            </>
        );
    }
}

export default ProductContainer;