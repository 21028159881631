import React from 'react';
import {Link} from "react-router-dom";

import './DetailsLink.scss';

const DetailsLinkComponent = (props) => {
    return (
        <Link
            to={props.href}
            className={`${props.classList} details-link`}
            onClick={props.onClick}
        >
            {props.children}
        </Link>
    );
};

export default DetailsLinkComponent;