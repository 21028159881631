import React from 'react';

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import './Filters.scss';

const FiltersComponent = () => {
    return (
        <div className="filters">
            <Accordion defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <h3>Hale</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Filtre Hale</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className="active">
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        <h3>Panouri termoizolante cu spumă rigidă de poliuretan</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className="group">
                                <h4>Tip panouri</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-1'
                                    label='Panouri perete'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-2'
                                    label='Panouri acoperiș'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-3'
                                    label='Panouri LEAD'
                                />
                            </div>

                            <div className="group">
                                <h4>Nervuri</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-4'
                                    label='3 nervuri'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-5'
                                    label='5 nervuri'
                                />
                            </div>

                            <div className="group">
                                <h4>Fixare</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-6'
                                    label='ascunsa'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-7'
                                    label='vizibila'
                                />
                            </div>

                            <div className="group">
                                <h4>Miez</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-8'
                                    label='PUR'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-9'
                                    label='PIR'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-10'
                                    label='PIR XV'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-11'
                                    label='PIR LEAD'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-12'
                                    label='Vata minerala'
                                />
                            </div>

                            <div className="group">
                                <h4>Microprofilare</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-13'
                                    label='standard'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-14'
                                    label='Liss'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-15'
                                    label='Pliss'
                                />
                            </div>

                            <div className="group">
                                <h4>Aplicații</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-16'
                                    label='industrial'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-17'
                                    label='comercial'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-18'
                                    label='agrozootehnic'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-19'
                                    label='camere frigorifice'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-20'
                                    label='aplicații igienă strictă'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-21'
                                    label='aplicașii bituminoase'
                                />
                            </div>

                            <div className="group">
                                <h4>Țara de producție</h4>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-22'
                                    label='România'
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-23'
                                    label='Serbia'
                                />
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                        <h3>Profile termoizolante cu vată minerală</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>Filtre Profile termoizolante cu vată minerală</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                        <h3>Profile zincate din oțel</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>Filtre Profile zincate din oțel</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4">
                        <h3>Produse conexe</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>Filtre Produse conexe</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5">
                        <h3>Tablă cutată autoportantă</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>Filtre Tablă cutată autoportantă</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="6">
                        <h3>Armături pentru profile PVC, uși și ferestre</h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>Filtre Armături pentru profile PVC, uși și ferestre</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};

export default FiltersComponent;