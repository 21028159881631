import React from 'react';
import {Link} from 'react-router-dom';

import './DocumentationCard.scss';

import IconDownload from './img/icon-download.png';

const DocumentationCardComponent = () => {
    return (
        <div className="documentation-card">
            <h4>Documentatie BIM Document cu titlu</h4>
            <p>Scurta descriere a documentului pe doua randuri, demo text.</p>
            <Link to="#"><img src={IconDownload} alt=""/></Link>
        </div>
    );
};

export default DocumentationCardComponent;