import React from 'react';
import {Route, Switch} from "react-router-dom";

import Categories from "./components/categories/main/CategoriesContainer";
import Product from "./components/product/main/ProductContainer";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Categories} />
            <Route exact path="/product" component={Product} />
        </Switch>
    );
};

export default Routes;