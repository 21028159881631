import React from 'react';

import Button from "../../../common/button/main/ButtonComponent";
import DetailsLink from "../../../common/details-link/main/DetailsLinkComponent";
import CtaQuote from "../../../common/cta-quote/main/CtaQuoteComponent"
import DocumentationCard from "../subcomponents/documentation-card/main/DocumentationCardComponent";

import { useMediaQuery } from 'react-responsive'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Table from 'react-bootstrap/Table'
import Nav from 'react-bootstrap/Nav'


import "./Product.scss";

import Envelope from "../../categories/main/img/icon-envelope.png";
import ImgProduct1 from "./img/image-product-1.jpg";
import MapRo from "./img/map-ro.jpg";
import Asistenta from "./img/asistenta.jpg";
import Aplicatii from "./img/aplicatii.jpg";
import IconTerasteel from "./img/icon-terasteel.png";
import IconRightWhite from "./img/icon-right-white.png";
import IconRight from "./img/icon-right.png";
import IconDown from "./img/icon-down.png";

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: ImgProduct1,
    thumbnail: ImgProduct1,
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  }
];


const ProductComponent = (props) => {
    const isMobile = useMediaQuery({
        query: '(max-device-width: 576px)'
    });

    return (
        <div className="product-content">
            <Button
                href="#"
                classList="ask-offer"
            >
                <img src={Envelope} alt=""/>
                SOLICITĂ OFERTĂ
            </Button>

            <Container fluid className="breadcrumbs-nav">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Panouri termoizolante</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Panouri termoizolante cu spumă rigidă de poliuretan</Breadcrumb.Item>
                    <Breadcrumb.Item active>Panouri perete</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <div className="product-short-info">
                <Container fluid>
                    <Row>
                        <Col lg={5} md={6}>
                            <div className="product-gallery">
                                <ImageGallery
                                    items={images}
                                    thumbnailPosition={isMobile ? 'bottom' : 'right' }
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                />
                            </div>
                        </Col>
                        <Col lg={7} md={6}>
                            <div className="short-description">
                                <h2>Panou termoizolant de perete cu îmbinare ascunsă RO</h2>
                                <p>Panourile sandwich de perete cu îmbinare ascunsă IsoPer A reprezintă o soluție excelentă pentru a satisfice cele mai exigente cerințe estetice. Aceste panouri pot fi montate atât orizontal, cât și vertical, permițând crearea unor pereți de înaltă valoare decorativă.</p>
                                <Table responsive className="custom-table">
                                    <tbody>
                                    <tr>
                                        <td className="name">Lungime</td>
                                        <td className="value">2000 - 13.500 mm</td>
                                    </tr>
                                    <tr>
                                        <td className="name">Abateri</td>
                                        <td className="value">&lt;3m: &plusmn;5mm; &gt;3m: &plusmn;10mm</td>
                                    </tr>
                                    <tr>
                                        <td className="name">Latime</td>
                                        <td className="value">1000mm ±2mm</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <Button
                                    href="#"
                                >
                                    Cere ofertă
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="map-projects">
                <Container fluid>
                    <div className="map-img">
                        <img src={MapRo} alt=""/>
                        <div className="cta">
                            <h3>Harta Proiectelor <br/>
                                TeraSteel</h3>
                            <Button
                                href="#"
                                classList="btn-with-icon play-btn"
                            >
                                Vezi harta
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>

            <div className="product-full-info">
                <Container fluid>
                    <Nav justify variant="tabs" defaultActiveKey="#descriere">
                        <Nav.Item>
                            <Nav.Link href="#descriere">Descriere</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#detalii" eventKey="#detalii">Detalii</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#aplicatii" eventKey="#aplicatii">Aplicații</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#documentatie-bim" eventKey="#documentatie-bim">Documentații BIM</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>

                <div id="descriere" className="description-section">
                    <Container fluid>
                        <Row>
                            <Col lg={9} md={8}>
                                <div className={`description-text ${props.hiddenText ? 'hidden' : 'show'}`}>
                                    <h3 className="section-title">Descriere</h3>
                                    <p>Recomandat clădirilor industriale și comerciale, unde estetica este importantă</p>
                                    <p>La pereții cu îmbinări ascunse, capetele elementelor de fixare sunt mascate, iar pe tronsoanele montate orizontal, sunt prevăzute rosturi de dilatare.</p>
                                    <ol>
                                        <li>Tablă din oţel zincat prevopsită conform EN 10143, EN 10346 și EN 10169, edițiile în vigoare. Microprofilări: STANDARD, PLISSE, LIS.</li>
                                        <li>Miezul: cu spumă rigidă de poliuretan sub diferite sisteme chimice: PUR, PIR, PIRXV</li>
                                        <li>Garnitura de etanşare: asigură o bună izolare termică şi etanşeitatea îmbinării. Panourile D= 40 mm, se livrează</li>
                                    </ol>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur autem consectetur cupiditate deleniti dolores eveniet explicabo hic illo iste iusto labore minima, minus molestiae nihil ratione saepe soluta voluptates?</p>
                                </div>

                                <DetailsLink
                                    href="#"
                                    onClick={() => props.showText()}
                                    classList={`${props.hiddenText ?  "show-more" : "show-less"}`}
                                >
                                    {`${props.hiddenText ?  "Vezi mai multe" : "Vezi mai putin"}`}  <img src={IconDown} alt=""/>
                                </DetailsLink>
                            </Col>
                            <Col lg={3} md={4}>
                                <div className="banner-text-link">
                                    <img src={Asistenta} alt=""/>
                                    <div className="cta">
                                        <h3>Asistenta TeraSteel</h3>
                                        <DetailsLink
                                            href="#"
                                        >
                                            Vezi mai multe <img src={IconRightWhite} alt=""/>
                                        </DetailsLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <div className="cta-catalog">
                        <Container fluid>
                            <div className="see-catalog">
                                <h4>Vezi Catalog Produse</h4>

                                <div className="description">
                                    <p>Gasesti toate produsele Isoper pe care te poti baza!</p>
                                    <Button
                                        href="#"
                                        classList="btn-with-icon download-btn"
                                    >
                                        Catalog
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>


                <div id="detalii" className="details-section">
                    <Container fluid>
                        <h3 className="section-title">Detalii</h3>
                        <div className="description-text">
                            <Row>
                                <Col lg={4}>
                                    <div className="details-info">
                                        <h4>Caracteristici principale ale panourilor sandwich IsoPer N</h4>
                                        <ul>
                                            <li>au o greutate proprie redusă, între 9-14 kg/mp;</li>
                                            <li>au un coeficient de transfer termic redus față de alte materiale de construcții;</li>
                                            <li>prezintă impermeabilitate la aer și umiditate datorită procentului ridicat de celule închise din spuma poliuretanică.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="details-info">
                                        <h4>Accesorii</h4>
                                        <p>Produsele sunt livrate împreună cu toate elementele necesare montajului:</p>
                                        <ul>
                                            <li>Accesorii metalice (elemente de tinichigerie) – sunt livrate în aceleași culori ca panourile.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="details-info">
                                        <h4>Ambalare</h4>
                                        <p>Panourile sandwich de perete cu îmbinare ascunsă sunt ambalate pe dimensiuni și culori, direct pe linia de fabricație, fiind protejate cu folie extensibilă din PE, pentru a fi evitată orice deteriorare a fețelor prevopsite. Numărul de panouri din fiecare pachet depinde de lungimea și grosimea acestora.</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details-info">
                                        <h4>Tolerantele dimensionale conform EN 14509 – 2013:</h4>
                                        <Table responsive className="custom-table">
                                            <tbody>
                                            <tr>
                                                <td className="name">Lungime&lt;=3000 mm</td>
                                                <td className="value"> &plusmn;5mm;</td>
                                            </tr>
                                            <tr>
                                                <td className="name">Lungime &gt;3000 mm</td>
                                                <td className="value">&plusmn;10mm</td>
                                            </tr>
                                            <tr>
                                                <td className="name">Abatere de la perpendicularitate</td>
                                                <td className="value">6mm</td>
                                            </tr>
                                            <tr>
                                                <td className="name">Latime utila</td>
                                                <td className="value">&plusmn;2mm</td>
                                            </tr>
                                            <tr>
                                                <td className="name">Grosime (D)&lt;100 mm </td>
                                                <td className="value">&plusmn;2mm</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div id="aplicatii" className="applications-section">
                    <Container fluid>
                        <h3 className="section-title">Aplicatii</h3>
                        <Row>
                            <Col md={5}>
                                <img src={Aplicatii} alt=""/>
                            </Col>
                            <Col md={6}>
                                <p className="date">2019</p>
                                <div className="title">
                                    <h4>Hala productie - Chisinau, Republica Moldova</h4>
                                    <img src={IconTerasteel} alt=""/>
                                </div>
                                <Table responsive className="custom-table">
                                    <tbody>
                                    <tr>
                                        <td className="name">Tip panou</td>
                                        <td className="value">Perete de acoperis 80mm; Ral 9006</td>
                                    </tr>
                                    <tr>
                                        <td className="name">Cantitate</td>
                                        <td className="value">Cca. 16 600 mp.</td>
                                    </tr>
                                    <tr>
                                        <td className="name">Obiectiv</td>
                                        <td className="value">Hala Productie</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <DetailsLink
                                    href="#"
                                >
                                    Vezi toate aplicațiile <img src={IconRight} alt=""/>
                                </DetailsLink>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div id="documentatie-bim" className="documentation-section">
                    <Container fluid>
                        <h3 className="section-title">Documentatii BIM</h3>
                        <div className="description-text">
                            <Row>
                                <Col md={6}>
                                    <ul>
                                        <li>au o greutate proprie redusă, între 9-14 kg/mp;</li>
                                        <li>au un coeficient de transfer termic redus față de alte materiale de construcții;</li>
                                        <li>prezintă impermeabilitate la aer și umiditate datorită procentului ridicat de celule închise din spuma poliuretanică.</li>
                                    </ul>
                                </Col>

                                <Col md={6}>
                                    <p>Produsele sunt livrate împreună cu toate elementele necesare montajului:</p>
                                    <ul>
                                        <li>Accesorii metalice (elemente de tinichigerie) – sunt livrate în aceleași culori ca panourile.</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>

                        <div className="documentation-cards-listing">
                            <Row>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                                <Col lg={3} md={4} sm={6}>
                                    <DocumentationCard />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>

            <Container fluid>
                <CtaQuote />
            </Container>
        </div>
    );
};

export default ProductComponent;