import React from 'react';
import {Link} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import SocialIcons from "../../../common/social-icons/main/SocialIconsComponents"

import './Header.scss';

import Logo from './img/terasteel-logo.png';
import Teraplast from './img/teraplast-group.png'
import IconPhone from './img/icon-phone.png';
import IconSearch from './img/icon-search.png';
import FlagRo from './img/flag-ro.png'

const HeaderComponent = (props) => {
    return (
        <div className="main-header">
            <div className="top-nav">
                <Container fluid>
                    <div className="top-nav-elements">
                        <div className="logo d-none d-lg-block">
                            <Link to="#"><img src={Logo} alt=""/></Link>
                        </div>
                        <div className="search-form">
                            <div className="header-phone">
                                <div className="phone" id="phone">
                                    <img src={IconPhone} alt=""/>
                                    {!props.showPhone ?
                                        <span>0741 xx xx xx</span> :
                                        <Link to="#" className="phone-no">0741 11 11 11</Link>
                                    }

                                    {!props.showPhone && <Link onClick={() => props.handleShowPhone()} to="#" className="show-phone">Arata telefon</Link>}

                                </div>
                                <ul className="languages">
                                    <li><img src={FlagRo} alt=""/><p>Română</p></li>
                                </ul>
                            </div>
                            <Form inline>
                                <FormControl type="text" placeholder="Caută..." />
                                <Button className="submit-button"><img src={IconSearch} alt=""/></Button>
                            </Form>
                        </div>
                        <Link className="companies" to="#">
                            <p>Companii</p>
                            <img src={Teraplast} alt=""/>
                        </Link>
                    </div>
                </Container>
            </div>

            <Navbar expand="lg">
                <Container fluid>
                    <div className="logo d-lg-none">
                        <Link to="#"><img src={Logo} alt=""/></Link>
                    </div>

                    <Navbar.Toggle aria-controls="main-menu" />
                    <Navbar.Collapse id="main-menu">
                        <Nav className="mr-auto">
                            <Nav.Link href="#">Companie</Nav.Link>
                            <Nav.Link href="#">Panouri termoizolante</Nav.Link>
                            <Nav.Link href="#">Hale la cheie</Nav.Link>
                            <Nav.Link href="#">Profile metalice</Nav.Link>
                            <Nav.Link href="#">Tablă cutată</Nav.Link>
                            <Nav.Link href="#">Conexe</Nav.Link>
                            <Nav.Link href="#">Aplicații</Nav.Link>
                            <Nav.Link href="#">Asistență</Nav.Link>
                            <Nav.Link href="#">Media</Nav.Link>
                            <Nav.Link href="#">Contact</Nav.Link>
                        </Nav>

                        <SocialIcons />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default HeaderComponent;