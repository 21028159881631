import React from 'react';

import Header from "./header/main/HeaderContainer";
import Footer from "./footer/main/FooterComponent";

const LayoutComponent = (props) => {
    return (
        <>
            <Header />
            {props.children}
            <Footer />
        </>
    );
};

export default LayoutComponent;