import React from 'react';
import {Link} from "react-router-dom";

import './SocialIcons.scss'

import IconFacebook from "./img/icon-facebook.png";
import IconLinkedin from "./img/icon-linkedin.png";
import IconYoutube from "./img/icon-youtube.png";
import IconInstagram from "./img/icon-instagram.png";

const SocialIconsComponents = () => {
    return (
        <ul className="social-icons">
            <li><Link to="#"><img src={IconFacebook} alt=""/></Link></li>
            <li><Link to="#"><img src={IconLinkedin} alt=""/></Link></li>
            <li><Link to="#"><img src={IconYoutube} alt=""/></Link></li>
            <li><Link to="#"><img src={IconInstagram} alt=""/></Link></li>
        </ul>
    );
};

export default SocialIconsComponents;