import React, {Component} from 'react';

import Header from "./HeaderComponent";

class HeaderContainer extends Component {
    state = {
        showPhone: false,
    };

    showPhone = () => {
        this.setState({
            ...this.state,
            showPhone: true,
        })
    };

    render() {
        return (
            <>
                <Header
                    showPhone={this.state.showPhone}
                    handleShowPhone={this.showPhone}
                />
            </>
        );
    }
}

export default HeaderContainer;