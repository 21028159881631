import React from 'react';

import Button from "../../../common/button/main/ButtonComponent";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './CtaQuote.scss';

import CalculatorHale from "./img/calculator-hale.jpg";
import Proiect from "./img/proiectul-tau.jpg";



const CtaQuoteComponent = () => {
    return (
        <div className="cta-quote">
            <Row>
                <Col sm={6}>
                    <div className="quote-card">
                        <div className="img">
                            <img src={CalculatorHale} alt=""/>
                        </div>
                        <h3>Calculator preț hale</h3>
                        <p>Configurează prețul pentru hala ta</p>
                        <Button
                            href="#"
                        >
                            Calculator preț Hale
                        </Button>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="quote-card">
                        <div className="img">
                            <img src={Proiect} alt=""/>
                        </div>
                        <h3>Proiectul tău</h3>
                        <p>Cere o ofertă de preț pentru proiectul tău</p>
                        <Button
                            href="#"
                        >
                            Cere ofertă
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CtaQuoteComponent;