import React from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss'

import Routes from './routes';
import Layout from "./layout/LayoutComponent";


const App = () => {
  return (
      <Router>
        <Layout>
            <Routes />
        </Layout>
      </Router>
  );
};


export default App;
