import React from 'react';
import {Link} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";

import SocialIcons from "../../../common/social-icons/main/SocialIconsComponents"

import './Footer.scss';

import Logo from './img/terasteel-logo.png';



const FooterComponent = () => {
    return (
        <div className="main-footer">
            <div className="footer-newsletter">
                <Container fluid>
                    <div className="newsletter-form">
                        <div className="text">
                            <h2>Abonează-te la newsletter-ul TeraSteel</h2>
                        </div>

                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" className="email-newsletter" placeholder="Adresa e-mail" />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox" className="info-acord">
                                <p className="info-newsletter-text">Fii la curent cu ofertele noastre și tutoriale din universul TeraSteel. Newsletter este trimis bi-lunar.</p>
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id='checkbox-acord'
                                    label='Sunt de acord cu prelucrarea datelor cu caracter personal'
                                    className='checkbox-acord'
                                />
                            </Form.Group>
                            <Button className="submit-btn" type="submit">Abonează-te</Button>
                        </Form>
                    </div>
                </Container>
            </div>

            <footer className="footer-info">
                <Container fluid>
                    <div className="footer-top">
                        <div className="logo">
                            <img src={Logo} alt=""/>
                        </div>

                        <div className="footer-widgets">
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link href="#">Companie</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Panouri termoizolante</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Hale la cheie</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Profile metalice</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Tablă cutată</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Conexe</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Aplicații</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Asistență</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Media</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Contact</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <div className="widget-area">
                                <div className="widget">
                                    <h6>Legal</h6>
                                    <ul>
                                        <li><Link to="#">Termeni și condiții</Link></li>
                                        <li><Link to="#">Politica cookies</Link></li>
                                        <li><Link to="#">Politica de confidențialitate</Link></li>
                                    </ul>
                                </div>
                                <div className="widget">
                                    <h6>Adresa</h6>
                                    <p>Sediu: Sat Săraţel, Comuna Șieu-Magheruș, DN 15A, km 45+500, Județul Bistriţa-Năsăud</p>
                                    <SocialIcons />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright">
                        <p>Copyright © Terasteel 2020. Toate drepturile rezervate</p>
                    </div>
                </Container>
            </footer>
        </div>
    );
};

export default FooterComponent;