import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Features.scss';

import IconCO2 from "./img/icon-co2.png";
import IconEnergy from "./img/icon-energy.png";
import IconShield from "./img/icon-shield.png";
import IconGear from "./img/icon-gear.png";
import IconCycle from "./img/icon-cycle.png";
import IconFire from "./img/icon-fire.png";

const FeaturesComponent = () => {
    return (
        <div className="footer-features">
            <Container fluid>
                <Row>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconCO2} alt=""/>
                            </div>
                            <p>Reducerea <br/>amprentei de CO2</p>
                        </div>
                    </Col>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconEnergy} alt=""/>
                            </div>
                            <p>Eficiență Energetică <br/>Superioară</p>
                        </div>
                    </Col>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconShield} alt=""/>
                            </div>
                            <p>Rezistență la <br/>factori externi</p>
                        </div>
                    </Col>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconGear} alt=""/>
                            </div>
                            <p>Optimizarea <br/>Costurilor</p>
                        </div>
                    </Col>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconCycle} alt=""/>
                            </div>
                            <p>Reutilizarea <br/>materialelor</p>
                        </div>
                    </Col>
                    <Col lg={2} sm={4} xs={6}>
                        <div className="feature">
                            <div className="img">
                                <img src={IconFire} alt=""/>
                            </div>
                            <p>Rezistența <br/>la foc</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FeaturesComponent;